import Head from 'next/head';
import {Row, Col} from 'antd';
import Link from 'next/link';
import Image from 'next/image';
import Arrow from '../svg/arrow-tr.svg';
import {Button, Section, Block, TopBar, GetStarted} from '../components/ui';
import styles from '../styles/home.module.sass';

export default function Home() {
  return (
    <div className={styles.root}>
      <Head>
        <title>ILO Quote</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <TopBar>
        <Block>
          <div>
            This application should take under 3 minutes to complete, providing
            you have the information required at hand.
          </div>
          <Link href="/about">
            <Button href="/about" size="large" type="link">
              WHAT IS AN ILO? <Arrow />
            </Button>
          </Link>
        </Block>
      </TopBar>

      <Section className={styles.homeSection}>
        <div className={styles.rays}></div>
        <Block>
          <Row align="middle" gutter={[40, 20]}>
            <Col xs={24} sm={12}>
              <div>
                <div className={styles.cap}>
                  Ready to supercharge your company?
                </div>
                <h1>Get A Quote For Your ILO</h1>

                <p>
                  Leverage your predicted revenue in return for capital and a
                  supercharged promotion. Take the first step towards maximising
                  your company’s potential by securing a listing on ILOCX.
                </p>

                <GetStarted>
                  <Link href="/onboarding">
                    <Button wide href="/onboarding" size="huge" type="primary">
                      Get Started
                    </Button>
                  </Link>
                </GetStarted>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <Image
                src="/about/hero-asset.png"
                alt="Hero"
                width={580 * 2}
                height={480 * 2}
              />
            </Col>
          </Row>
        </Block>
      </Section>

      <footer className={styles.disclaimer}>
        <Block>
          ILO Quote is currently in beta and term sheets issued are considered
          preliminary until manually reviewed.
        </Block>
      </footer>
    </div>
  );
}
